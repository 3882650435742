<template>
  <div class="w-40 p-3">
    <b-card class="text-center">
      <b-row>
        <b-col><i class="ti-cloud-up"></i></b-col>
      </b-row>
      <div class="row mb-3">
        <div class="col-sm-12">
          <img src="@/assets/logo.png" alt="ared-logo" />
        </div>
      </div>
      <b-card-text class="text-dark">Reset Password</b-card-text>
      <hr />
      <b-card-body class="w-100 flex justify-center">
        <b-form method="post" @submit.prevent="resetPassword">
          <b-form-row
            ><b-col cols="10" class="mx-auto">
              <b-form-group ref="password">
                <b-input-group
                  class="mt-3"
                  ref="new-password"
                  prepend="New Password"
                >
                  <b-form-input
                    ref="password"
                    name="password"
                    v-model.trim="form.password"
                    v-validate="'required|min:6|max:50'"
                    :type="passFieldType"
                    required
                    placeholder="password"
                  ></b-form-input>
                  <b-input-group-append>
                    <div class="input-group-append showPassDiv">
                      <i
                        :class="[
                          'form-control show_password_icon',
                          showPass ? 'i-Eye-Scan' : 'i-Eye-Visible',
                        ]"
                        @click="showPassword"
                      ></i>
                    </div>
                  </b-input-group-append>
                </b-input-group>
                <small v-show="errors.has('password')" class="text-danger">{{
                  errors.first("password")
                }}</small>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row
            ><b-col cols="10" class="mx-auto">
              <b-form-group ref="confirm">
                <b-input-group class="mt-3" prepend="Confirm Password">
                  <b-form-input
                    data-vv-as="password"
                    v-model.trim="form.confirm"
                    v-validate="{ required: true, confirmed: form.password }"
                    name="password_confirmation"
                    :type="passConfirmFieldType"
                    required
                    placeholder="confirm password"
                  ></b-form-input>
                  <b-input-group-append>
                    <div class="input-group-append showPassDiv">
                      <i
                        :class="[
                          'form-control show_password_icon',
                          showConfirmPass ? 'i-Eye-Scan' : 'i-Eye-Visible',
                        ]"
                        @click="showConfirmPassword"
                      ></i>
                    </div>
                  </b-input-group-append>
                </b-input-group>
                <small
                  v-show="errors.has('password_confirmation')"
                  class="text-danger"
                  >{{ errors.first("password_confirmation") }}</small
                >
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-button
            class="btn w-30 text-center"
            size="sm"
            type="submit"
            variant="primary"
            :disabled="submiting"
          >
            <span class="mx-auto">{{ buttonText }}</span>
          </b-button>
        </b-form>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "NewPassword",
  props: {
    uid: {
      type: String,
      default() {
        return "";
      },
    },
    token: {
      type: String,
      default() {
        return "";
      },
    },
  },
  data() {
    return {
      form: {
        password: "",
        confirm: "",
      },
      showPass: false,
      showConfirmPass: false,
      passFieldType: "password",
      passConfirmFieldType: "password",
      buttonText: "Submit",
    };
  },

  methods: {
    showPassword() {
      this.showPass = !this.showPass;
    },
    showConfirmPassword() {
      this.showConfirmPass = !this.showConfirmPass;
    },
    async resetPassword() {
      this.submitting = true;
      try {
        this.buttonText = "Submitting...";

        const data = {
          uid: this.uid,
          token: this.token,
          new_password: this.form.password,
        };
        await axios
          .post(`v1/auth/users/reset_password_confirm/`, data)
          .then((response) => {
            this.buttonText = "Reset Password";

            if (response.status === 204) {
              this.$router.push({ name: "login" });
              this.$swal("Success", `Password reset successful`, "success");
            }
          });
      } catch (e) {
        this.submitting = false;

        this.buttonText = "Reset Password";

        if (e.response.data.detail) {
          this.$toast.info(e.response.data.detail);
        } else if (
          e.response.data.new_password &&
          e.response.data.new_password.length
        ) {
          this.$swal("", e.response.data.new_password[0], "error");
        } else if (e.response.data.token && e.response.data.token.length) {
          this.$swal(
            "",
            "Password reset link expired. Initiate the process again to receive a new link in your email",
            "error"
          );
        } else {
          this.$swal("", `Could Not reset pass.`, "info");
        }
      }
    },
  },
  watch: {
    showPass() {
      if (this.showPass === true) {
        this.passFieldType = "text";
      } else {
        this.passFieldType = "password";
      }
    },
    showConfirmPass() {
      if (this.showConfirmPass === true) {
        this.passConfirmFieldType = "text";
      } else {
        this.passConfirmFieldType = "password";
      }
    },
  },
};
</script>

<style scoped>
.btn-primary {
  background-color: #d4af5f !important;
}
</style>
