<template>
  <header class="login-header">
    <div class="d-flex justify-content-center">
      <NewPassword :uid="$route.query.uid" :token="$route.query.token" />
    </div>
  </header>
</template>

<script>
import NewPassword from "../../../components/Auth/NewPassword";
export default {
  components: { NewPassword }
};
</script>
