var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-40 p-3"},[_c('b-card',{staticClass:"text-center"},[_c('b-row',[_c('b-col',[_c('i',{staticClass:"ti-cloud-up"})])],1),_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-sm-12"},[_c('img',{attrs:{"src":require("@/assets/logo.png"),"alt":"ared-logo"}})])]),_c('b-card-text',{staticClass:"text-dark"},[_vm._v("Reset Password")]),_c('hr'),_c('b-card-body',{staticClass:"w-100 flex justify-center"},[_c('b-form',{attrs:{"method":"post"},on:{"submit":function($event){$event.preventDefault();return _vm.resetPassword.apply(null, arguments)}}},[_c('b-form-row',[_c('b-col',{staticClass:"mx-auto",attrs:{"cols":"10"}},[_c('b-form-group',{ref:"password"},[_c('b-input-group',{ref:"new-password",staticClass:"mt-3",attrs:{"prepend":"New Password"}},[_c('b-form-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|min:6|max:50'),expression:"'required|min:6|max:50'"}],ref:"password",attrs:{"name":"password","type":_vm.passFieldType,"required":"","placeholder":"password"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.password"}}),_c('b-input-group-append',[_c('div',{staticClass:"input-group-append showPassDiv"},[_c('i',{class:[
                        'form-control show_password_icon',
                        _vm.showPass ? 'i-Eye-Scan' : 'i-Eye-Visible',
                      ],on:{"click":_vm.showPassword}})])])],1),_c('small',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('password')),expression:"errors.has('password')"}],staticClass:"text-danger"},[_vm._v(_vm._s(_vm.errors.first("password")))])],1)],1)],1),_c('b-form-row',[_c('b-col',{staticClass:"mx-auto",attrs:{"cols":"10"}},[_c('b-form-group',{ref:"confirm"},[_c('b-input-group',{staticClass:"mt-3",attrs:{"prepend":"Confirm Password"}},[_c('b-form-input',{directives:[{name:"validate",rawName:"v-validate",value:({ required: true, confirmed: _vm.form.password }),expression:"{ required: true, confirmed: form.password }"}],attrs:{"data-vv-as":"password","name":"password_confirmation","type":_vm.passConfirmFieldType,"required":"","placeholder":"confirm password"},model:{value:(_vm.form.confirm),callback:function ($$v) {_vm.$set(_vm.form, "confirm", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.confirm"}}),_c('b-input-group-append',[_c('div',{staticClass:"input-group-append showPassDiv"},[_c('i',{class:[
                        'form-control show_password_icon',
                        _vm.showConfirmPass ? 'i-Eye-Scan' : 'i-Eye-Visible',
                      ],on:{"click":_vm.showConfirmPassword}})])])],1),_c('small',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('password_confirmation')),expression:"errors.has('password_confirmation')"}],staticClass:"text-danger"},[_vm._v(_vm._s(_vm.errors.first("password_confirmation")))])],1)],1)],1),_c('b-button',{staticClass:"btn w-30 text-center",attrs:{"size":"sm","type":"submit","variant":"primary","disabled":_vm.submiting}},[_c('span',{staticClass:"mx-auto"},[_vm._v(_vm._s(_vm.buttonText))])])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }